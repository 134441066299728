import React from 'react';
import { Helmet } from 'react-helmet';
import '../../styles/template.css';
import '../../styles/about.css';

import Layout from '../../components/Layout';

// markup
const Page = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Racqy - About us</title>
      </Helmet>
      <div>
        <main id="about">
          <section className="content-section">
            <div className="page-content">
              <div className="col">
                <h2 style={{ fontWeight: 'bold' }}>About</h2>
                <p className="large">
                  At Racqy, we strive to create a more dynamic and connected
                  world where everyone can easily find and connect with others
                  who share their interests. Whether you’re a seasoned athlete,
                  a hobbyist, looking for new social connections, or seeking
                  professional development and work opportunities, we provide
                  the tools and resources you need to pursue your passions and
                  do more of what you love.
                  <br />
                  With access to thousands of users and a wide range of
                  activities, we are the go-to destination for people of all
                  skill levels and interests.
                </p>
                <br />
                <p className="large">
                  From sports and hobbies to professional development and social
                  events, we have something for everyone. And because we believe
                  that everyone should have the opportunity to live their best,
                  most active life, our platform is always free to use.
                  <br />
                  Join us on our journey to inspire and enable healthy, active
                  living for all. Let Racqy be your guide to finding like-minded
                  individuals, discovering new activities, and creating
                  meaningful connections in the world around you.
                </p>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default Page;
